



























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Form as elForm } from "element-ui";
import { GetUser, PostEditUser } from "@/request/account";
@Component({})
export default class Name extends Vue {
  private data: any = {
    头像: "",
  };
  private isEdit: boolean = false;
  private getData() {
    const params = {
      doctor_id: this.$store.state.user.doctor_id,
      source: "pc医生",
    };
    GetUser(this, params).then((res: any) => {
      this.data = res;
      this.$store.commit("updateUser", res);
    });
  }
  private save() {
    if (!this.data["姓名"]) {
      this.$message.error("请填写姓名");
      return;
    }
    if (!this.data["职称"]) {
      this.$message.error("请选择职称");
      return;
    }
    const params = JSON.parse(JSON.stringify(this.data));
    PostEditUser(this, params).then((res: any) => {
      this.$message.success("保存成功");
      this.getData();
    });
  }
  mounted() {
    this.getData();
  }
}
