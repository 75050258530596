import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 登录
 */
const PostLogin = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/admin/login_pc", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            loading.close()
            reject();
        });
    });
};
/**
 * @description 获取用户信息
 */
const GetUser = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/doctor_detail", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 修改账户信息
 */
const PostEditUser = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/doctor_change_self_info", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 修改密码
 */
const editPassword = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/doctor_change_password", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
export { PostLogin, GetUser, PostEditUser, editPassword }

